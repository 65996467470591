import SERVICE_URL from "../config/API_DATA"
import HttpPost from "../http/httppost.js";
import Logger from './logger.js';
import { FILE_NAME, LOG_TYPE } from "../config/API_DATA";

export default class ChangePassword {
    static buildRequest(pOldPwd, pNewPwd) {
        let returnValue = null;
        try {


            let body = {
                oldPassword: pOldPwd,
                newPassword: pNewPwd
            }


            returnValue = body;
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_CHANGE_PASSWORD, "error in building body for ChangePassword api", err.toString(), "buildRequest", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }

    static async getResponse(pReqOptions) {


        let returnValue = null;
        try {
            returnValue = await HttpPost.makeHttpPostRequest(SERVICE_URL.CHANGE_PASSWORD, pReqOptions);
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_CHANGE_PASSWORD, "error in getting Response from  ChangePassword api ", err.toString(), "getResponse", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }
}