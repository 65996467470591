<template>
  <div>
    <!-- <CRow class="justify-content-center"> -->
    <ValidationObserver v-slot="{ handleSubmit }">
      <CForm @submit.prevent="handleSubmit(changePwdSubmit)">
        <CCard class=" ">
          <!-- <CCol sm="12" md="26"> -->
          <!-- <CCard> -->

          <CCardHeader class="grey-header">
            <CRow class="flex-flow-column">
              <CCol>
                <strong>{{ $t("chngPwd.title") }}</strong>
              </CCol>
              <p class="desc">{{ $t("chngPwd.desc") }}</p>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <div class="change-pass-form">
              <cTextInputComponent
                :isAutoComplete="false"
                v-model="password.currPwd"
                type="password"
                :placeholder="$t('chngPwd.currPwdPl')"
                :name="$t('chngPwd.currPwd')"
                :label="$t('chngPwd.currPwd')"
                rules="required"
                class="mb-10px"
              />

              <cTextInputComponent
                v-model="password.newPwd"
                type="password"
                :placeholder="$t('chngPwd.newPwdPl')"
                :name="$t('chngPwd.newPwd')"
                :label="$t('chngPwd.newPwd')"
                iconName="cil-info"
                :showIcon="showIcon"
                vid="confirmation"
                rules="required|validPwd"
                class="mb-10px"
              />
              <cTextInputComponent
                v-model="password.cnfrmPwd"
                type="password"
                :placeholder="$t('chngPwd.cnfrmPl')"
                :name="$t('chngPwd.cnfrmPwd')"
                :label="$t('chngPwd.cnfrmPwd')"
                rules="required|confirmed:confirmation"
              />
            </div>
            <CAlert v-if="alertValue" show color="danger">
              <div v-if="alertValue && alertValue.hasOwnProperty('valueList')">
                <p v-for="(value, index) in alertValue.valueList" :key="index">
                  {{ value }}
                </p>
              </div>
              <div v-if="alertValue && alertValue.hasOwnProperty('codeList')">
                <span>{{ $t("cAlert.errorCode") + " : " }}</span
                ><span
                  v-for="(code, index) in alertValue.codeList"
                  :key="index"
                >
                  {{ code + " " }}
                </span>
              </div>
              <div v-if="alertValue && alertValue.constructor == String">
                <p>
                  {{ alertValue }}
                </p>
              </div>
            </CAlert>
          </CCardBody>
          <footer class="card-footer d-flex justify-content-end">
            <div class="mr-15px">
              <CButton
                color="danger"
                class="action-c-btn"
                @click="navigateToAllUsers()"
              >
                <img src="@imgPath/img/svg/icons/close.svg" />
                {{ $t("chngPwd.cancel") }}
              </CButton>
            </div>

            <div>
              <CButton
                color="success"
                type="submit"
                class="action-c-btn"
                @click="rule = false"
                ><img src="@imgPath/img/svg/icons/correct.svg" />
                {{ $t("chngPwd.chngPwd") }}
              </CButton>
            </div>
          </footer>
        </CCard>
      </CForm>
    </ValidationObserver>
    <cAlertModal
      :buttonList="getModalBtnLs()"
      :status="1"
      :show="showAlertModal"
      :desc="$t('chngPwd.alert')"
    >
    </cAlertModal>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";

import { mapActions } from "vuex";

import { PATHNAME, COMPONENT_NAME } from "./../../util/constants";
import apiChangePassword from "./../../services/apiChangePassword";

import Logger from "./../../services/logger";
import { FILE_NAME, LOG_TYPE } from "./../../config/API_DATA";
import {
  VALIDATE_SUCCESS_RES,
  getErrorMessage,
} from "./../../util/apiValidate";
import {
  handleJWT,
  getErrorAlertValue,
  addClassToBody,
  removeClassBody,
} from "../../util/util";
import cAlertModal from "../../components/form/cAlertModal";
import cTextInputComponent from "../../components/form/cTextInputComponent";
import { navigate } from "../../router/index";
export default {
  name: COMPONENT_NAME.CHANGE_PASSWORD,
  components: {
    cTextInputComponent,
    ValidationObserver,
    cAlertModal,
  },
  data: () => ({
    showIcon: true,

    iconName: null,
    showAlertModal: false,
    alertValue: null,
    password: {
      currPwd: null,
      newPwd: null,
      cnfrmPwd: null,
    },
  }),
  methods: {
    ...mapActions("sChangePassword", {
      SET_CHANGE_PWD_REQ: "SET_CHANGE_PWD_REQ",
      SET_CHANGE_PWD_RES: "SET_CHANGE_PWD_RES",
      CLEAR_CHANGE_PWD_STORE: "CLEAR_CHANGE_PWD_STORE",
    }),

    getModalBtnLs() {
      return [
        {
          name: this.$t("chngPwd.ok"),
          func: this.navigateToAllUsers,
          color: "success",
        },
      ];
    },

    toggleAlertModal() {
      this.showAlertModal = !this.showAlertModal;
    },

    showAlert(value) {
      this.alertValue = value;
    },

    navigateToAllUsers() {
      try {
        navigate(PATHNAME.USERS);
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.CHANGE_PASSWORD,
          "error in  navigate To All Users",
          err.toString(),
          "navigateToAllUsers()",
          LOG_TYPE.ERROR
        );

        Logger.getResponse(body);
      }
    },
    changePwdSubmit: async function () {
      try {
        if (this.password && this.password.currPwd && this.password.newPwd) {
          let changePwdStatus = await this.getChangePwdStatus(
            this.password.currPwd,
            this.password.newPwd
          );

          if (changePwdStatus) {
            this.toggleAlertModal();
          }
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.CHANGE_PASSWORD,
          "error in  change Pwd Submit",
          err.toString(),
          "changePwdSubmit()",
          LOG_TYPE.ERROR
        );

        Logger.getResponse(body);
      }
    },

    async getChangePwdStatus(pOldPwd, pNewPwd) {
      let returnValue;

      try {
        let changePwdRequest = apiChangePassword.buildRequest(pOldPwd, pNewPwd);

        if (changePwdRequest != null) {
          this.SET_CHANGE_PWD_REQ(changePwdRequest);

          let changePwdResponse = await apiChangePassword.getResponse(
            changePwdRequest
          );
          if (VALIDATE_SUCCESS_RES(changePwdResponse)) {
            returnValue = changePwdResponse.data;
            this.SET_CHANGE_PWD_RES(returnValue);
            this.showAlert(null);
          } else {
            let error = getErrorMessage(changePwdResponse);

            let isJwt = handleJWT(error);
            if (!isJwt) {
              this.showAlert(getErrorAlertValue(error));
            }
          }
        } else {
          throw "invalid request";
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.CHANGE_PASSWORD,
          "error in  get Change password Status",
          err.toString(),
          "getChangePasswordStatus()",
          LOG_TYPE.ERROR
        );

        Logger.getResponse(body);
      }
      return returnValue;
    },
  },
  beforeCreate() {
    addClassToBody(COMPONENT_NAME.CHANGE_PASSWORD);
  },

  beforeDestroy() {
    removeClassBody();
  },
};
</script>
